import { defineComponent, h } from "vue";
import { and, isControl, rankWith, scopeEndsWith } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import gSelect from "@/shared/components/gSelect.vue";

export const entry = {
    renderer: defineComponent({
        name: "deviceApiSelect",
        props: {
            ...rendererProps(),
        },
        setup(props) {
            return useJsonFormsControl(props);
        },
        mounted() {
            // to handle if have default text
            if (this.control.schema.defaultValue) {
                this.handleChange(this.control.path, this.control.schema.defaultValue);
            }
        },
        render() {
            const control = this.control;
            const schemaOptions = control.schema.options;
            const multiple = schemaOptions.multiple ?? false;
            const filters = schemaOptions.filters ?? {};
            const customQuery = Object.keys(filters)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
                .join("&");

            return h(gSelect, {
                modelValue: control.data,
                api: schemaOptions.api,
                dense: true,
                outlined: true,
                label: control.schema.label,
                optionLabel: "label",
                optionValue: "label",
                searchKey: "label",
                multiple,
                useChips: multiple,
                mapOptions: true,
                emitValue: true,
                required: control.required,
                customQuery: `&${customQuery}`,
                "onUpdate:modelValue": (event) => {
                    this.handleChange(control.path, event);
                },
            });
        },
    }),
    tester: rankWith(
        1,
        and(isControl, (schema) => schema.type === "deviceApiSelect"),
    ),
};
