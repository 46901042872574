import { and, isControl, rankWith } from "@jsonforms/core";
import fileRenderer from "./components/fileRenderer.vue";

export const entry = {
    renderer: fileRenderer,
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type == "filerenderer"),
    ),
};
