import QBtn from 'quasar/src/components/btn/QBtn.js';import QInput from 'quasar/src/components/input/QInput.js';;
import { defineComponent, h } from "vue";
import { and, isControl, rankWith } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import QIcon from 'quasar/src/components/icon/QIcon.js';;
import ToolTip from "@/shared/components/ToolTip.vue";

export const durationRenderer = defineComponent({
    name: "durationRender",
    props: {
        ...rendererProps(),
    },
    setup(props) {
        const { control, handleChange } = useJsonFormsControl(props);
        return { control, handleChange };
    },
    mounted() {
        // to handle if have default text
        if (this.control.schema.defaultValue) {
            const data = this.control.schema.defaultValue.split(" ");
            this.hr = data[0];
            this.min = data[1];
            this.sec = data[2];

            this.handleChange(this.control.path, this.control.schema.defaultValue);
        }
    },
    data() {
        return {
            hr: 0,
            min: 0,
            sec: 0,
        };
    },

    methods: {
        updatedInput(e, seg) {
            if (seg == "hr") {
                this.hr = e;
            } else if (seg == "min") {
                this.min = e;
            } else if (seg == "sec") {
                this.sec = e;
            }
            let hrValue = `${this.hr}hr`;
            let minValue = `${this.min}min`;
            let secValue = `${this.sec}sec`;

            const value = `${hrValue} ${minValue} ${secValue}`;
            this.handleChange(this.control.path, value);
        },
    },
    render() {
        const { control, hr, min, sec, updatedInput } = this;

        return h("div", { className: "duration-select-wrap" }, [
            h(
                "div",
                { className: "renderer-label-container" },
                h("p", { className: "renderer-label" }, control?.schema?.label || "", [
                    h("span", { className: "renderer-required" }, control.required ? "*" : ""),
                ]),
                control?.schema?.additionalInfo
                    ? h(QIcon, { name: "info", color: "primary" }, [
                          h(ToolTip, h("b", control?.schema?.additionalInfo)),
                      ])
                    : null,
            ),
            h("div", { className: "multi-duration-select" }, [
                h(QInput, {
                    modelValue: hr,
                    type: "number",
                    outlined: true,
                    dense: true,
                    min: 0,
                    error: !!control.errors,
                    errorMessage: control.errors,
                    "onUpdate:modelValue": (value) => {
                        updatedInput(value, "hr");
                    },
                }),
                h("p", { className: "renderer-label" }, "Hr"),
                h(QInput, {
                    modelValue: min,
                    type: "number",
                    outlined: true,
                    dense: true,
                    error: !!control.errors,
                    errorMessage: control.errors,
                    "onUpdate:modelValue": (value) => {
                        updatedInput(value, "min");
                    },
                }),
                h("p", { className: "renderer-label" }, "Min "),
                h(QInput, {
                    modelValue: sec,
                    type: "number",
                    outlined: true,
                    dense: true,
                    error: !!control.errors,
                    errorMessage: control.errors,
                    "onUpdate:modelValue": (value) => {
                        updatedInput(value, "sec");
                    },
                }),
                h("p", { className: "renderer-label" }, "Sec"),
            ]),
        ]);
    },
});

export const entry = {
    renderer: durationRenderer,
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type === "duration"),
    ),
};
