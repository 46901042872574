import { defineComponent, h } from "vue";
import { and, isControl, rankWith } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import QToggle from 'quasar/src/components/toggle/QToggle.js';;
import QIcon from 'quasar/src/components/icon/QIcon.js';;
import ToolTip from "@/shared/components/ToolTip.vue";

export const entry = {
    renderer: defineComponent({
        name: "toggleRender",
        props: {
            ...rendererProps(),
        },
        setup(props) {
            const { control, handleChange } = useJsonFormsControl(props);
            return { control, handleChange };
        },
        mounted() {
            // to handle if have default text
            if (this.control.schema.defaultValue) {
                this.handleChange(this.control.path, this.control.schema.defaultValue);
            }
        },
        render() {
            const { control, handleChange } = this;
            const modelValue = control.data !== null && control.data !== undefined ? control.data : false;

            return h("div", {}, [
                h(
                    "div",
                    { className: "renderer-label-container" },
                    h("p", { className: "renderer-label" }, control?.schema?.label || "", [
                        h("span", { className: "renderer-required" }, control.required ? "*" : ""),
                    ]),
                    control?.schema?.additionalInfo
                        ? h(QIcon, { name: "info", color: "primary" }, [
                              h(ToolTip, h("b", control?.schema?.additionalInfo)),
                          ])
                        : null,
                ),

                h(QToggle, {
                    modelValue: modelValue,
                    outlined: true,
                    dense: true,
                    label: control.schema.label,
                    error: !!control.errors,
                    errorMessage: control.errors,
                    "onUpdate:modelValue": (event) => {
                        handleChange(control.path, event);
                    },
                }),
            ]);
        },
    }),
    tester: rankWith(
        1,
        and(isControl, (schema) => schema.type === "toggle"),
    ),
};
