import axios from "axios";
import { apiBaseUrl } from "@/environment/environment";
import userService from "./user-service";
import toaster from "./alert-service";
import errorHandler from "@/utilities/errorHandler";
import common from "./common";
const config = {
    baseURL: apiBaseUrl,
};

let access = localStorage.getItem("access_token");

setInterval(() => {
    let access = localStorage.getItem("access_token");
    httpClient.defaults.headers.common["Authorization"] = "Bearer" + " " + access;
}, 1000);

export const httpClient = axios.create(config);

httpClient.defaults.xsrfHeaderName = "X-CSRFTOKEN";
httpClient.defaults.xsrfCookieName = "csrftoken";
httpClient.defaults.headers.common["Content-Type"] = "application/json";
httpClient.defaults.headers.common["Content-Type"] = "multipart/form-data";

// Http client request interceptor
httpClient.interceptors.request.use(
    async (config) => {
        if (config.url != "/api/token/refresh/") {
            access = localStorage.getItem("access_token");
            config.headers = {
                Authorization: `Bearer ${access}`,
            };
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

function loadLoginPage() {
    const loginUrlBase = localStorage.getItem("x-log-role") || "auth";
    let redirectUrl = `/${loginUrlBase}/login/`;
    if (window.self !== window.top) {
        // checking if it is an iframe
        window.parent.location.href = redirectUrl;
    } else {
        window.location.href = redirectUrl;
    }
}

// Http client response interceptor
httpClient.interceptors.response.use(
    async (resp) => {
        return resp;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response) {
            if (error.response.status == 401 && !originalRequest._retry) {
                if (error.config.url == "/api/token/refresh/" || error.config.url != "/current-login/") {
                    common.clearLocalStorage();
                    await userService.logout();
                    loadLoginPage();
                    return;
                }
                originalRequest._retry = true;
                try {
                    const { data } = await httpClient.post(
                        "/api/token/refresh/",
                        { refresh: localStorage.getItem("refresh_token") },
                        {
                            withCredentials: true,
                        },
                    );
                    const accessToken = data.access;
                    access = accessToken;
                    localStorage.setItem("access_token", accessToken);
                    httpClient.defaults.headers.common["Authorization"] = accessToken;
                } catch (error) {
                    if (error.response && error.response.data) {
                        return Promise.reject(error.response.data);
                    }
                    return Promise.reject(error);
                }
            } else if (error.response.status == 511) {
                let userResponse = await userService.getcurrentLogin();
                if (userResponse.status == 401) {
                    common.clearLocalStorage();
                    await userService.logout();
                    loadLoginPage();
                }
            } else if (
                error.response.status === 400 &&
                error.request.responseURL.indexOf("/login/?next=") != -1
            ) {
                // Handle the redirect to the login page
                common.clearLocalStorage();
                loadLoginPage();
            }
        }
        return Promise.reject(error);
    },
);
export const refrshAccessToken = () => {
    return new Promise((resolve, reject) => {
        httpClient
            .post(
                "/api/token/refresh/",
                { refresh: localStorage.getItem("refresh_token") },
                {
                    withCredentials: true,
                },
            )
            .then(function (response) {
                return resolve(response.data.access);
            })
            .catch(function (error) {
                return reject(error);
            });
    });
};

export const API = {
    getPendingList: async () => {
        try {
            const response = await httpClient.get("/iotapp/registration-requests/", false);
            return response;
        } catch (error) {
            toaster.error(`Error Fetching Pending Lists`);
            return null;
        }
    },
};

export default {
    async get(url, showAlert, message) {
        try {
            const response = await httpClient.get(url, false);
            return response;
        } catch (error) {
            if (error.response == undefined) {
                return error;
            } else {
                if (showAlert) errorHandler.errorHandler(error.response, message);
            }
            return error;
        }
    },
    post(url, data, showAlert, message = {}) {
        return new Promise((resolve, reject) => {
            try {
                httpClient
                    .post(url, data, { method: "POST" })
                    .then((res) => {
                        resolve(res);
                        if (res.status == "200" || res.status == "201") {
                            if (showAlert == true && message[res.status]) {
                                toaster.success(message[res.status]);
                            } else if (showAlert == false) {
                                toaster.hide();
                            } else {
                                toaster.success("Successfully Created");
                            }
                        } else {
                            toaster.hide();
                        }
                    })
                    .catch((error) => {
                        if (error.response == undefined) {
                            resolve(error);
                        } else {
                            if (showAlert) {
                                errorHandler.errorHandler(error.response, message);
                                resolve(error.response);
                            }
                            resolve(error.response);
                        }
                    });
            } catch (e) {
                reject(null);
            }
        });
    },

    delete(url, showAlert, message) {
        return new Promise((resolve, reject) => {
            try {
                httpClient
                    .delete(url, { method: "DELETE" })
                    .then((res) => {
                        resolve(res);
                        if (res.status == "200" || res.status == "204") {
                            if (showAlert == true && message) {
                                toaster.success(message[200]);
                            } else if (showAlert == false) {
                                toaster.hide();
                            } else {
                                toaster.success("Successfully Deleted");
                            }
                        } else {
                            toaster.hide();
                        }
                    })
                    .catch((error) => {
                        if (error.response == undefined) {
                            resolve(error);
                        } else {
                            if (showAlert != false) {
                                errorHandler.errorHandler(error.response, message);
                                resolve(error.response);
                            }
                            resolve(error.response);
                        }
                    });
            } catch (e) {
                reject(null);
            }
        });
    },
    put(url, data, showAlert, message = {}) {
        return new Promise((resolve, reject) => {
            try {
                httpClient
                    .put(url, data, { method: "PUT" })
                    .then((res) => {
                        resolve(res);
                        if (res.status == "200") {
                            if (showAlert == true && message[res.status]) {
                                toaster.success(message[res.status]);
                            } else if (showAlert == false) {
                                toaster.hide();
                            } else {
                                toaster.success("Successfully Updated");
                            }
                        } else {
                            toaster.hide();
                        }
                    })
                    .catch((error) => {
                        if (error.response == undefined) {
                            resolve(error);
                        } else {
                            if (showAlert) {
                                errorHandler.errorHandler(error.response, message);
                                resolve(error.response);
                            }
                            resolve(error.response);
                        }
                    });
            } catch (e) {
                reject(null);
            }
        });
    },
};
