import moment from "moment";

// Date Validator
function dateValidator(input, format = "YYYY-MM-DD") {
    if (input === undefined || input === null) {
        return false;
    }
    if (typeof input === "number") {
        input = input.toString();
    }
    if (typeof input !== "string") {
        return false;
    }
    const isValid = moment(input, format, true).isValid();
    return {
        isValid,
        message: isValid ? "Passed" : "Date validation failed",
    };
}

// DateTime Validator
function dateTimeValidator(input, format = "YYYY-MM-DDTHH:mm:ss") {
    if (input === undefined || input === null) {
        return false;
    }
    if (typeof input === "number") {
        input = input.toString();
    }
    if (typeof input !== "string") {
        return false;
    }
    const isValid = moment(input, format, true).isValid();
    return {
        isValid,
        message: isValid ? "Passed" : "DateTime validation failed",
    };
}

// Number Validator
function numberValidator(input, min, max) {
    if (typeof input === "undefined" || input === null || isNaN(input) || typeof input === "boolean") {
        return { isValid: false, message: "Input Value is not a Number" };
    }
    const num = Number(input);
    if ((min !== undefined && num < min) || (max !== undefined && num > max)) {
        return {
            isValid: false,
            message:
                min !== undefined && num < min
                    ? `Input Value cannot be less than ${min}`
                    : `Input Value cannot be greater than ${max}`,
        };
    }
    return { isValid: true, message: "Passed" };
}

function requiredValidator(input, field) {
    if (input === undefined || input === null || input === "") {
        return {
            isValid: false,
            message: `${field} is required`,
        };
    }
    return {
        isValid: true,
        message: "Passed",
    };
}

/**
 * @param {Object} validation
 * @param {any} input
 */
function validator(validations, key, input) {
    for (let validation of validations) {
        let isValid = true;
        let message = "";
        let interpreter = {};

        switch (validation.name) {
            case "required":
                interpreter = requiredValidator(input, key);
                isValid = interpreter.isValid;
                message = interpreter.message;
                break;
            case "date":
                interpreter = dateValidator(input, validation.format);
                isValid = interpreter.isValid;
                message = interpreter.message;
                break;
            case "dateTime":
                interpreter = dateTimeValidator(input, validation.format);
                isValid = interpreter.isValid;
                message = interpreter.message;
                break;
            case "number":
                interpreter = numberValidator(input, validation.min, validation.max);
                isValid = interpreter.isValid;
                message = interpreter.message;
                break;
            default:
                isValid = true;
                message = "Unknown validation";
        }

        if (!isValid) {
            return {
                instancePath: `/${key}`,
                message: message,
                schemaPath: "",
                keyword: "",
                params: {},
            };
        }
    }

    return null;
}

export default validator;
