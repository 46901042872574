import { useConfigStore } from "@/store/configStore/configStore";
import httpClient, { httpClient as axios } from "./http-client";
import toast from "@/shared/services/alert-service";

export const TICKET_TYPES = /**@type {const} */ ({
    "ai-alarms": {
        text: "AI Audit",
        type: "AIAlarmTicket",
        icon: "insights",
        key: "ai-alarms",
    },
    "device-health": {
        text: "Device Health",
        type: "AlarmTicket",
        icon: "memory",
        key: "device-health",
    },
    escalations: {
        text: "Escalation",
        type: "TICKET",
        icon: "confirmation_number",
        key: "escalations",
    },
    "user-activity": {
        text: "User Activity",
        type: "UserActivityTicket",
        icon: "rowing",
        key: "user-activity",
    },
    all: {
        text: "All",
        type: null,
        icon: "confirmation_number",
        key: "all",
    },
});

/**@type {StatusCard[]} */
export const DEFAULT_STATUS_CARDS = [
    {
        name: "OPEN",
        styleLabel: "open",
    },
    {
        name: "INPROGRESS",
        styleLabel: "progress",
    },
    {
        name: "ESCALATE",
        styleLabel: "escalate",
    },
    {
        name: "RESOLVED",
        styleLabel: "resolved",
    },
    {
        name: "CLOSED",
        styleLabel: "closed",
    },
].map((v) => ({ ...v, count: 0, value: false }));

/**@type {ReturnType<useConfigStore>} */
let configStore;

/**
 * @param {keyof TICKET_TYPES} type
 * @returns {typeof TICKET_TYPES[type]} */
export const getCurrentTicketType = (type) => {
    configStore = configStore ?? useConfigStore();

    const alias = configStore.getTicketTypeAlias(type);
    const data = TICKET_TYPES[type] || TICKET_TYPES.all;

    return {
        ...data,
        text: alias ? alias : `${data.text} ${configStore.getTicketAlias()}s`,
    };
};

export function getRouterKey(type) {
    let rValue = "";
    Object.values(TICKET_TYPES).forEach((v) => {
        if (v.type === type) {
            rValue = v.key;
            return;
        }
    });
    return rValue;
}

export function createLocalStorageKey(type = "default") {
    return /**@type {const} */ (`ticketConfig_${type}`);
}

export const TABLE_ACTIONS = /**@type {const} */ ([
    "thumbnailView",
    "compactView",
    "exportTickets",
    "searchBox",
    "onlyMyTickets",
    "autoRefreshToggle",
    "addTicketButton",
    "statusCards",
    "modifyTableHeader",
    "filterBox",
    "groupByDay",
]);

/**
 * @typedef {import("@/types").TicketLocalConfig} TicketConfig
 * @typedef {keyof TicketConfig} ConfigKey
 */

export const ticketLocalStorageService = /**@type {const} */ ({
    /**@param {ConfigKey} key */
    updateLocalStorage(key, value, configKey) {
        const existing = localStorage.getItem(configKey);
        if (!existing) {
            const configData = {
                [key]: value,
            };
            localStorage.setItem(configKey, JSON.stringify(configData));
            return;
        }
        const data = JSON.parse(existing);
        data[key] = value;
        localStorage.setItem(configKey, JSON.stringify(data));
    },
    /**@param {ConfigKey} key  */
    getValueFromLocalStorage(key, configKey) {
        const str = localStorage.getItem(configKey);
        if (!str) return undefined;
        const parsed = JSON.parse(str);
        return parsed[key];
    },
});

export const ticketService = {
    /**@returns {Promise<string[]>} */
    async getAllStatus() {
        const response = await httpClient.get("/helpdesk/status/");
        return response?.data?.contents || [];
    },
    async getAllPriority() {
        const response = await httpClient.get("/helpdesk/priority/");
        return response?.data?.contents || [];
    },
    /**
     * @param {{id:number,status:string}} payload
     * @param {() => void} callback
     */
    async updateTicket(payload, callback, showAlert = true) {
        const message = { 200: "Ticket updated successfully" };
        await httpClient.put(`/helpdesk/tickets/${payload.id}/`, payload, showAlert, message).then(() => {
            if (callback instanceof Function) callback();
        });
    },
    /**@returns {Promise<import("@/types").DeviceData | null>} */
    async getCameraById(id) {
        try {
            const { data } = await axios.get(`/iotapp/devices/${id}/`);
            return data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async getAssetById(id) {
        try {
            const { data } = await axios.get(`/iotapp/assets/${id}/`);
            return data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    // export ticket
    getValueFromNestedProperty(object, keys) {
        let value = object;
        for (const key of keys) {
            if (value !== null && typeof value === "object" && key in value) {
                value = value[key];
            } else {
                // Handle the case where the key does not exist in the object
                return undefined;
            }
        }
        return value;
    },
    sortItemForExport(item, exportConfig, visibleColumns) {
        const transformedItem = {};

        for (const key in exportConfig) {
            if (visibleColumns.length === 0 || visibleColumns.includes(key.split(".")[0])) {
                const val = exportConfig[key];
                const keys = key.split(".");
                let value = this.getValueFromNestedProperty(item, keys);
                if (keys.length == 2 && keys[0] == "statusAgeing") {
                    if (keys[1].includes("Duration") && value) {
                        let durationInMinutes = +value;
                        value = this.convertNumberToAge(durationInMinutes);
                        if (item.status.toLowerCase() === keys[1].replace("Duration", "")) {
                            const statusDate = keys[1].replace("Duration", "Date");
                            const v = item.statusAgeing[statusDate];
                            if (v) {
                                value = this.calculateTimeDiffInMin(v, durationInMinutes);
                            }
                        }
                    } else {
                        const statusDate = keys[1].replace("Duration", "Date");
                        const v = item.statusAgeing[statusDate];
                        if (v) {
                            value = this.calculateTimeDiffInMin(v);
                        }
                    }
                }
                transformedItem[val] = value;
            }
        }
        return transformedItem;
    },
    calculateTimeDiffInMin(v, add = 0) {
        const date = new Date(v);
        const currentDate = new Date();
        const diff = Math.abs(currentDate - date) / 60000 + add;
        return this.convertNumberToAge(diff);
    },
    convertNumberToAge(minutes) {
        const days = Math.floor(minutes / (24 * 60));
        const remainingHours = Math.floor((minutes % (24 * 60)) / 60);
        const remainingMinutes = Math.round(minutes % 60);

        let result = "";

        if (days > 0) {
            result += `${days} day${days > 1 ? "s" : ""}, `;
        }

        if (remainingHours > 0) {
            result += `${remainingHours} hour${remainingHours > 1 ? "s" : ""}, `;
        }

        if (remainingMinutes > 0) {
            result += `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
        }
        result = result.replace(/,\s*$/, "");

        return result;
    },
    createUrl(baseUrl, id, historyId) {
        const base = `${baseUrl}/view/?ticketId=${id}`;

        return historyId ? `${base}&historyId=${historyId}` : base;
    },

    async getLatestHistoryId(id) {
        try {
            const response = await axios.get(
                `/helpdesk/tickets/${id}/history/?page=1&limit=1&orderBy=-updatedAt`,
            );
            return response.data.contents[0]?.history_id;
        } catch (error) {
            console.error(`Error getting latest history from ticket ID: ${id}`);
            return null;
        }
    },

    async getLatestComment(id) {
        try {
            const response = await axios.get(
                `/helpdesk/tickets/${id}/comments/?page=1&limit=1&orderBy=-updatedAt`,
            );
            return response.data.contents[0];
        } catch (error) {
            console.error(`Error getting latest comment from ticket ID: ${id}`);
            return null;
        }
    },
    /**@returns {Promise<import("@/types").Ticket>} */
    async updateStatus(ticketData, status) {
        ticketData.status = status;
        if (status == "RESOLVED") {
            ticketData.resolvedDate = new Date().toISOString();
        }

        return httpClient
            .put(`/helpdesk/tickets/${ticketData.id}/`, ticketData, false)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err);
                return null;
            });
    },
    retryCount: 0,
    maxRows: 10000,
    cancelFetch: false,
    controller: null,
    async removePageAndLimit(url) {
        const updatedUrl = url.replace(/([?&])page=[^&]*(&|$)/, "$2").replace(/([?&])limit=[^&]*(&|$)/, "$2");
        return updatedUrl;
    },

    async fetchAllTickets(q, maxRows) {
        this.maxRows = maxRows;

        const query = await this.removePageAndLimit(q);
        const ticketData = [];
        let pagination = {
            page: 1,
            limit: 100,
            hasNext: true,
            totalCount: 0,
        };
        await this.fetchTicketByPage(pagination, ticketData, query);
        return ticketData;
    },

    async fetchTicketByPage(pagination, ticketData, query) {
        if (this.controller?.signal?.aborted) {
            return;
        }
        try {
            const response = await axios.get(
                `/helpdesk/tickets/?page=${pagination.page}&limit=${pagination.limit}${query}`,
            );
            const data = response.data.contents;
            ticketData.push(...data);
            pagination.totalCount = response.data.totalCount;

            this.retryCount = 0;
            const maxRows = this.maxRows;

            if (ticketData.length < maxRows && ticketData.length < pagination.totalCount) {
                await new Promise((resolve) => setTimeout(resolve, 500));
                pagination.page++;
                await this.fetchTicketByPage(pagination, ticketData, query);
            }
        } catch (error) {
            this.retryCount++;
            console.error("Error fetching tickets:", error);

            if (this.retryCount > 3) {
                console.error("retry count exceeded: ", this.retryCount);
                toast.error(`Error generating CSV, Please try agin later!`);
                return;
            }
            // wait after 2 seconds
            await new Promise((resolve) => setTimeout(resolve, 2000));
            // Retry fetching the same page
            await this.fetchTicketByPage(pagination, ticketData, query);
        }
    },
};

/**
 * @typedef {import("@/types").StatusKeys} StatusKeys
 * @typedef {import("@/types").StatusCardProp} StatusCard
 */
/**@type {Record<StatusKeys, import("@/types").StatusStyles>} */
const STATUS_STYLE_MAP = {
    CLOSED: "closed",
    ESCALATE: "escalate",
    "RE-OPENED": "escalate",
    INPROGRESS: "progress",
    OPEN: "open",
    OVERDUE: "closed",
    RESOLVED: "resolved",
    INREVIEW: "review",
    FLAGGED: "escalate",
};

/**@param {StatusKeys} key  */
export function findStyleByStatusKey(key) {
    let style = STATUS_STYLE_MAP[key];
    if (key == "INPROGRESS") style = `in${style}`;
    const rValue = style ?? STATUS_STYLE_MAP.OPEN;
    return `${rValue}-status`;
}

/** @param {string} statusQuery */
export function generateStatusCards(statusQuery) {
    if (!statusQuery || statusQuery == "hide") return [];
    const statusArray = statusQuery.split(",");
    /**@type {StatusCard[]} */
    const rArray = [];

    for (const value of statusArray) {
        if (!value) continue;

        const v = value?.toUpperCase();
        rArray.push({
            name: v,
            styleLabel: getStatusStyle(v),
            count: 0,
            value: false,
        });
    }

    return rArray;
}

/**@param {StatusKeys} status */
function getStatusStyle(status) {
    // if undefined, return open status style
    return STATUS_STYLE_MAP[status] ?? STATUS_STYLE_MAP.OPEN;
}

const TICKET_FIELDS = {
    resolvedDate: "Resolved Date",
    assignedDate: "Assigned Date",
    eventDate: "Happened Date",
    dueDate: "Due Date",
    entityId: "Device",
    assignedTo: "Assigned To",
    title: "Summary",
    onHold: "On Hold",
    event: "Issue Type",
    subEvent: "Issue Sub Type",
};

/**
 * @param {keyof Ticket} ele
 * @param {string} assetAlias
 */
export function ticketKeyMap(ele, assetAlias = "Location") {
    if (ele == "locationId") return assetAlias;
    return TICKET_FIELDS[ele] || ele.charAt(0).toUpperCase() + ele.substr(1).toLowerCase();
}

/**
 * @param {Ticket['metadata']} data
 * @param {RecordViewPayload} overrides
 */
export function getRecordViewPayload(data, overrides = {}) {
    return {
        startTime: data.startTime,
        endTime: data.endTime,
        jobId: data.recordJobId,
        multiplePlaylist: false,
        ...overrides,
    };
}

/**
 * @typedef {import("@/types").Ticket} Ticket
 * @typedef {import("@/types").RecordViewPayload} RecordViewPayload
 */
