import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Import Quasar
import Quasar from 'quasar/src/vue-plugin.js';;
import "@quasar/extras/material-icons/material-icons.css";
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';;

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { CONSTANTS } from "@/shared/contants";
import { createPinia } from "pinia";

// custom global components
import BreadCrumbVue from "@/shared/components/BreadCrumb.vue";
import Spinner from "@/shared/components/Spinner.vue";
import ToolTip from "@/shared/components/ToolTip.vue";
import gSelect from "@/shared/components/gSelect.vue";
import PreLoaderVue from "@/shared/components/PreLoader.vue";

// Leaflet
import "leaflet/dist/leaflet.css";

import { createDynamicForms } from "@asigloo/vue-dynamic-forms";
import "@asigloo/vue-dynamic-forms/dist/themes/default.scss";

// localization
import { createI18n } from "vue-i18n";
import QSelectNoItems from "./shared/components/QSelectNoItems.vue";

function loadLocaleMessages() {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages || {};
}
const messages = loadLocaleMessages();
const i18n = createI18n({
    locale: navigator.language.split("-")[0],
    fallbackLocale: "en",
    messages,
});
// // dynamic form
const VueDynamicForms = createDynamicForms({});

const app = createApp(App);
const pinia = createPinia();

app.use(Quasar, {
    config: {},
    plugins: {
        Notify,
        Dialog,
        Loading,
        AppFullscreen,
    },
});

app.use(i18n);
app.use(router);
app.use(VueDynamicForms);
app.use(pinia);
library.add(fas);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("BreadCrumb", BreadCrumbVue);
app.component("ToolTip", ToolTip);
app.component("gSelect", gSelect);
// eslint-disable-next-line
app.component("Spinner", Spinner);
app.component("PreLoader", PreLoaderVue);
app.component("QSelectNoItems", QSelectNoItems);
app.mount("#app");

app.config.globalProperties.CONSTANTS = CONSTANTS;
