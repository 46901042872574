import { defineComponent, h } from "vue";
import { rankWith, scopeEndsWith } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import QBtn from 'quasar/src/components/btn/QBtn.js';;

const ButtonControlRenderer = defineComponent({
    name: "ButtonControlRenderer",
    props: {
        ...rendererProps(),
    },
    setup(props) {
        return useJsonFormsControl(props);
    },
    methods: {
        onChange(event) {
            // console.log(event)
            this.handleChange(this.control.path);
            this.$emit("submit", event);
        },
    },
    render() {
        return h(QBtn, { label: this.control.label, class: "primary-btn-sm", onClick: this.onChange });
    },
});

export const entry = {
    renderer: ButtonControlRenderer,
    tester: rankWith(2, scopeEndsWith("button")),
};
