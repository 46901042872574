<template>
    <div class="renderer-label-container">
        <p class="renderer-label">
            {{ control?.schema?.label }}
            <span class="renderer-required" v-if="control?.required">*</span>
        </p>
        <div v-if="control?.schema?.additionalInfo" class="icon-container">
            <q-icon name="info" class="text-primary icon-right">
                <ToolTip>
                    <b>{{ control?.schema?.additionalInfo }}</b>
                </ToolTip>
            </q-icon>
        </div>
    </div>
    <q-select
        dense
        outlined
        options-dense
        v-model="control.data"
        @update:model-value="selected"
        @input-value="filterOptions"
        :error="!!control.errors"
        :error-message="control.errors"
        use-input
        :options="filteredOptions">
        <template v-slot:option="scope">
            <q-expansion-item
                :expand-icon="hasChild(scope) ? '' : 'no_icon'"
                expand-separator
                group="somegroup"
                header-class="text-weight-bold"
                :label="scope.opt.label"
                @click="selected(scope.opt.label)">
                <template v-for="child in scope.opt.children" :key="child.label">
                    <q-item
                        clickable
                        v-ripple
                        v-close-popup
                        @click="selected(`${scope.opt.label} - ${child.label}`)"
                        :class="{ 'bg-light-blue-1': control.data === child.label }">
                        <q-item-section>
                            <q-item-label class="q-ml-md">{{ child.label }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-expansion-item>
        </template>
    </q-select>
</template>

<script>
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
export default {
    props: {
        ...rendererProps(),
    },
    setup(props) {
        return useJsonFormsControl(props);
    },
    data() {
        return {
            options: [],
            filteredOptions: [],
        };
    },
    mounted() {
        this.options = this.control.schema?.options;
        this.filteredOptions = this.options;
    },
    methods: {
        hasChild(scope) {
            return scope.opt.children && scope.opt.children.length > 0;
        },
        selected(e) {
            this.handleChange(this.control.path, e);
        },
        filterOptions(val) {
            if (val === "") {
                this.filteredOptions = this.options;
                return;
            }

            const filter = val.toLowerCase();
            const filterOptionsRecursive = (options) => {
                return options
                    .map((option) => {
                        if (option.label.toLowerCase().includes(filter)) {
                            return option;
                        }

                        if (option.children) {
                            const children = filterOptionsRecursive(option.children);
                            if (children.length) {
                                return { ...option, children };
                            }
                        }
                        return null;
                    })
                    .filter((option) => option !== null);
            };
            this.filteredOptions = filterOptionsRecursive(this.options);
        },
    },
};
</script>

<style scoped>
.renderer-label-container {
    display: flex;
    gap: 5px;
    cursor: pointer;
}
</style>
