<template>
    <div v-if="activeForms.length > 0">
        <div class="floating-form-action" v-if="fabType == 'un_docked'">
            <div class="un_docked-btn">
                <q-btn icon="rate_review" @click="docked_active = !docked_active" class="btn">
                    <ToolTip><b>Forms</b></ToolTip>
                </q-btn>
                <div class="menu-items" v-if="docked_active">
                    <div v-for="form in activeForms" :key="form.name" class="item">
                        <q-btn @click="submitForm(form.form)">
                            <q-icon :name="form.icon" />
                            <ToolTip
                                ><b>{{ form.label }}</b></ToolTip
                            >
                        </q-btn>
                    </div>
                    <div class="item">
                        <q-btn @click="undockFab('docked')">
                            <q-icon name="dock" color="dark"></q-icon>
                            <ToolTip><b>Collapse</b></ToolTip>
                        </q-btn>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="floating-docked-action">
            <q-btn
                :icon="docked_active ? 'chevron_right' : 'chevron_left'"
                class="docked-btn"
                @click="docked_active = !docked_active"></q-btn>
            <div class="fab-items" :class="docked_active ? 'active' : ''">
                <q-btn icon="rate_review">
                    <ToolTip><b>Forms</b></ToolTip>
                    <q-menu>
                        <q-list>
                            <q-item
                                clickable
                                v-close-popup
                                v-for="form in activeForms"
                                :key="form.name"
                                @click="submitForm(form.form)">
                                <q-item-section avatar class="form--item">
                                    <q-icon color="primary" :name="form.icon" />
                                </q-item-section>
                                <q-item-section class="form-item-label">{{ form.label }}</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>
                <q-btn @click="undockFab('un_docked')">
                    <q-icon name="dock" color="dark"></q-icon>
                    <ToolTip><b>Expand</b></ToolTip>
                </q-btn>
            </div>
        </div>
    </div>

    <q-dialog v-model="submitPopup" persistent>
        <q-card style="width: 700px; max-width: 80vw;" class="q-pa-md">
            <vueFormSubmit :template-name="selectedTemplate" @hidePop="hideForm" />
        </q-card>
    </q-dialog>
</template>

<script>
import { useConfigStore } from "@/store/configStore/configStore";
import routesMap from "@/router/routes.map";
import ToolTip from "@/shared/components/ToolTip.vue";
import vueFormSubmit from "./vueFormSubmit.vue";

export default {
    components: {
        vueFormSubmit,
    },
    setup() {
        const configStore = useConfigStore();

        return { configStore };
    },
    data() {
        return {
            forms: [],
            activeForms: [],
            routes: routesMap,
            fabType: "un_docked",
            docked_active: false,
            submitPopup: false,
            selectedTemplate: "",
        };
    },
    watch: {
        $route() {
            this.setActiveForms();
        },

        formConfig: {
            handler(newVal, oldval) {
                this.processFormConfig(newVal);
            },
            deep: true,
        },
    },
    computed: {
        formConfig() {
            return this.configStore.getFormConfig();
        },
    },
    async mounted() {
        if (localStorage.getItem("fab-state")) {
            this.fabType = atob(localStorage.getItem("fab-state"));
        }
        await this.configStore.setCustomer();
        this.processFormConfig(this.formConfig);
    },
    methods: {
        setActiveForms() {
            const currentRoutePath = this.normalizePath(this.$route.path);
            let routeName = null;
            for (const route of this.routes) {
                if (this.matchPath(route.path, currentRoutePath)) {
                    routeName = route.name;
                    break;
                }
            }
            if (routeName) {
                this.activeForms = this.forms.filter((f) => f.name === routeName);
            } else {
                this.activeForms = [];
            }
        },

        processFormConfig(formConfig) {
            const result = [];
            Object.keys(formConfig).forEach((key) => {
                formConfig[key].views.forEach((item) => {
                    const form = key;
                    result.push({
                        name: item,
                        form: form,
                        icon: formConfig[key]?.metadata?.icon || "maps_ugc",
                        label: formConfig[key]?.metadata?.label || "",
                    });
                });
            });
            this.forms = result;
            this.setActiveForms();
        },

        matchPath(routePath, currentPath) {
            const normalizedRoutePath = this.normalizePath(routePath);
            const normalizedCurrentPath = this.normalizePath(currentPath);
            const regex = this.pathToRegex(normalizedRoutePath);
            return regex.test(normalizedCurrentPath);
        },
        pathToRegex(path) {
            return new RegExp("^" + path.replace(/:[^\s/]+/g, "([^/]+)") + "$");
        },
        normalizePath(path) {
            return path.replace(/\/+$/, "");
        },
        undockFab(type) {
            this.fabType = type;
            localStorage.setItem("fab-state", btoa(this.fabType));
        },
        submitForm(formName) {
            this.selectedTemplate = formName;
            this.submitPopup = true;
        },
        hideForm() {
            this.submitPopup = false;
            this.selectedTemplate = "";
            this.docked_active = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.floating-form-action {
    position: fixed;
    bottom: 10%;
    right: 3%;
    background: $primary;
    color: white;
    border-radius: 50%;
    .un_docked-btn {
        height: 50px;
        width: 50px;
        display: grid;
        place-items: center;
        position: relative;
        .btn {
            width: 100%;
            height: 100%;
            background-color: $primary;
            border-radius: 50%;
            z-index: 999;
        }
        .menu-items {
            background-color: #44accf35;
            padding: 0 12px;
            padding-bottom: 30px;
            position: absolute;
            bottom: calc(100% - 30px);
            color: $primary;
            font-size: 30px;
            border-radius: 30px 30px 0 0;
            .item {
                cursor: pointer;
            }
        }
    }
}
.floating-docked-action {
    position: fixed;
    bottom: 10%;
    height: 50px;
    right: 0px;
    background: $primary;
    color: white;
    border-radius: 4px 0 0 4px;
    display: flex;
    .docked-btn {
        height: 100%;
    }

    .fab-items {
        width: 0px;
        height: 100%;
        display: flex;
        gap: 10px;
        background-color: #44accf;
        &.active {
            padding: 0 10px;
            width: 100%;
        }
    }
}

.form-item-label {
    font-weight: 1000;
}
</style>
