import { logger } from "@/shared/services/common";
import httpClient from "@/shared/services/http-client";
import { DEFAULT_STATUS_CARDS } from "@/shared/services/ticket-ui-service";
import userService, { USER_ROLES } from "@/shared/services/user-service";
import { defineStore } from "pinia";
// ******************************
// DON'T USE THIS FILE ANYMORE PLEASE USE CONFIGSTORE.JS FOR HANDLING CUSTOMER SETTINGS AND SYSTEM CONFIGURATIONS
// ******************************
// PLEASE KEEP NEW CONFIGURATION FORMAT WHICH AVAILABLE ON DEFAULT.JSON OR APPCONFIG IN TYPES.TS FILE
// ********************************

export const useNameStore = defineStore("nameStore", {
    state: () => ({
        /**@type {import("@/types").Clientconfig} */
        clientConfig: {},
        customer: null,
        isCustomerSet: false,
        /**@type {import("@/types").LocalUser} */
        userDetails: undefined,
        loadingUser: false,
    }),
    getters: {
        client: (state) => state.clientConfig,
        userData: (state) => state.userDetails,
        userEmail: (state) => state.userDetails?.email || "",
    },
    actions: {
        async setCustomer() {
            if (this.isCustomerSet) {
                return;
            }
            try {
                const response = await httpClient.get("/iotapp/customer-logo/");
                const customer = response.data.name;
                this.customer = customer;

                const configData = await this.loadClientConfig();
                this.clientConfig = configData;
                this.isCustomerSet = true;
            } catch (error) {
                console.error("Error setting customer:", error);
            }
        },
        /**@param {typeof this.clientConfig} data  */
        updateConfig(data) {
            this.clientConfig = data;
        },
        async loadClientConfig() {
            try {
                const response = await httpClient.get(`/iotapp/customer-settings/`);
                return response.data;
            } catch (error) {
                console.error("Error fetching clientConfig!\n", error);
                return {};
            }
        },
        /**@param {'asset'|'ticket'} key  */
        getByKey(key) {
            return this.client?.config?.alias[key]?.title || "";
        },
        getTicketAlias(withS = false) {
            let fallback = "Ticket";
            let label = this.client?.config?.alias["ticket"]?.title;
            if (withS) {
                fallback += "s";
                label && (label += "s");
            }
            return label || fallback;
        },
        getAssetAlias(withS = false) {
            let fallback = "Site";
            let label = this.client?.config?.alias["asset"]?.title;
            if (withS) {
                fallback += "s";
                label && (label += "s");
            }
            return label || fallback;
        },
        async setUserDetails() {
            try {
                this.loadingUser = true;
                this.userDetails = await userService.getCurrentUser();
                return this.userDetails;
            } finally {
                this.loadingUser = false;
            }
        },
        getStatusCards() {
            const r = this.client.config.alias.ticket.statusCardItems;
            return r || DEFAULT_STATUS_CARDS;
        },
        getTicketConfig() {
            return (
                this.client.config.alias.ticket.config || {
                    addTicket: true,
                    attachments: true,
                    history: true,
                    rating: true,
                    watcher: true,
                }
            );
        },
        isCustomerAdmin() {
            return this.userData?.userRole?.includes(USER_ROLES.customeradmin) ?? false;
        },
        /**@param {import("@/types").TicketUITypes} key  */
        getTicketTypeAlias(key) {
            try {
                let alias = this.client.config.alias.ticket.types[key];
                if (alias) alias += ` ${this.getTicketAlias(true)}`;
                return alias;
            } catch (error) {
                return undefined;
            }
        },
        /**@param {import("@/types").TicketTableHeaderField[]} items  */
        updateTableHeaders(items) {
            try {
                const config = this.client.config.alias.ticket?.tableHeaders ?? {};
                items.forEach((v) => {
                    const isEnabled = config[v.key] ?? v.visible;
                    v.visible = isEnabled;
                });
            } catch (error) {
                logger.error("Error when getting table headers from client config", { error });
            }
        },
        /**@param {import("@/types").TicketType} type  */
        getOrderBy(type) {
            const defaultValue = "updatedAt";
            if (type === "AIAlarmTicket") {
                return this.client?.config?.alias?.ticket?.orderBy || defaultValue;
            } else {
                return defaultValue;
            }
        },
    },
});
