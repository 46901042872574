import router from "@/router";
import toaster from "@/shared/services/alert-service";

export default {
    errorHandler(response, message) {
        if (response.status) {
            switch (response.status) {
                case 304:
                    if (message[300]) {
                        toaster.error(message[300]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 400:
                    if (message[400]) {
                        toaster.error(message[400]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 401:
                    if (message[401]) {
                        toaster.error(message[401]);
                    }
                    break;
                case 403:
                    if (message[403]) {
                        toaster.error(message[403]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 404:
                    if (message[404]) {
                        toaster.error(message[404]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 405:
                    if (message[405]) {
                        toaster.error(message[405]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 406:
                    if (message[406]) {
                        toaster.error(message[406]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 409:
                    if (message[409]) {
                        toaster.error(message[409]);
                    } else {
                        toaster.error(response.data.detail ?? response.statusText);
                    }
                    break;
                case 413:
                    if (message[413]) {
                        toaster.error(message[413]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 415:
                    if (message[415]) {
                        toaster.error(message[415]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 417:
                    if (message[417]) {
                        toaster.error(message[417]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 500:
                    if (message) {
                        toaster.error(message[500]);
                    } else {
                        toaster.error("Failed to connect server");
                    }
                    break;
                case 501:
                    if (message[501]) {
                        toaster.error(message[501]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 502:
                    if (message[502]) {
                        toaster.error(message[502]);
                    } else {
                        toaster.error(response.statusText);
                        setTimeout(() => {
                            router.replace({
                                path: "/login",
                            });
                        }, 1000);
                    }
                    break;
                case 503:
                    if (message[503]) {
                        toaster.error(message[503]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
                case 511:
                    if (message[511]) {
                        toaster.error(message[511]);
                    } else {
                        toaster.error(response.statusText);
                    }
                    break;
            }
        }
    },
};
