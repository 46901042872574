<template>
    <div class="cookie-consent" v-if="!consentGiven && !$route.meta.hideNavigation">
        <div class="cookie-consent-banner">
            <p>
                🍪 By using this website you agree to our <a href="/cookie-policy">cookie policy</a>
            </p>
        </div>
        <q-icon size="1.4em" class="icon-close" name="close" @click="onAccept"></q-icon>
    </div>
</template>

<script>
export default {
    data() {
        return {
            consentGiven: localStorage.getItem("cookie-consent") === "true",
        };
    },
    methods: {
        onAccept() {
            localStorage.setItem("cookie-consent", "true");
            this.consentGiven = true; // Update the data property
        },
    },
};
</script>

<style lang="scss" scoped>
.cookie-consent {
    position: fixed;
    width: 100%;
    height: 60px;
    border-top: 1px solid $primary;
    bottom: 0;
    left: 0; /* Added left: 0 to ensure it sticks to the left */
    z-index: 999999;
    background-color: $primary;
    color: #fff;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    a{
        color: $secondary-bg;
        text-decoration: underline;
    }
    .icon-close{
        cursor: pointer;
    }
    p {
        color: $white;
    }
}
</style>
