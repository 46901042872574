<template>
    <q-spinner color="primary" :size="size ? size : `3em`" />
</template>

<script>
export default {
    name: "SpinnerApp",
    props: ["size"]
};
</script>
