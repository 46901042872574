<template>
    <NavigationBar v-if="!$route.meta.hideNavigation && configStore.userData" />
    <main class="app-wrap">
        <SideNav v-if="!$route.meta.hideNavigation && configStore.userData" />
        <section class="content-wrap">
            <BreadCrumb :items="bitems" v-if="bitems.length > 1" />
            <router-view />
            <FooterApp v-if="!$route.meta.hideNavigation" />
        </section>
    </main>
</template>
<script>
import "@/styles/genset/style.scss";
import { useConfigStore } from "@/store/configStore/configStore";
import { useDashboard } from "@/composables/useDashboardConfig.js";
import { defineAsyncComponent } from "vue";
import BreadCrumb from "./utilities/BreadCrumb.vue";

const NavigationBar = defineAsyncComponent(() => import("@/components/containers/Navigation.vue"));
const SideNav = defineAsyncComponent(() => import("@/components/containers/sideNav/sideNav.vue"));
const FooterApp = defineAsyncComponent(() => import("@/components/containers/Footer.vue"));

export default {
    name: "gensetView",
    components: {
        NavigationBar,
        SideNav,
        FooterApp,
        BreadCrumb,
    },
    setup() {
        const configStore = useConfigStore();
        const { bitems } = useDashboard();
        return {
            configStore,
            bitems,
        };
    },
    watch: {
        $route: {
            handler(route) {
                if (window.location !== window.parent.location || route.name == "extension-app") {
                    route.meta.hideNavigation = true;
                } else {
                    route.meta.hideNavigation = false;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    async mounted() {
        if (!this.configStore.userData && !this.configStore.loadingUser) {
            await this.configStore.setUserDetails();
        }
    },
};
</script>
