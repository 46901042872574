import httpClient from "../../http-client";

export const fetchFormTemplateByName = async (name) => {
    try {
        const q = encodeURIComponent(name);
        const response = await httpClient.get(`/ifmapp/dynamic-form-templates/?name=${q}`, false);
        return response.data.contents;
    } catch (error) {
        console.error(error);
    }
};
