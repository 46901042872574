import { defineComponent, h } from "vue";
import { and, isControl, rankWith } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import QInput from 'quasar/src/components/input/QInput.js';;
import QIcon from 'quasar/src/components/icon/QIcon.js';;
import ToolTip from "@/shared/components/ToolTip.vue";

export const entry = {
    renderer: defineComponent({
        name: "RandomIDRenderer",
        props: {
            ...rendererProps(),
        },
        setup(props) {
            const { control, handleChange } = useJsonFormsControl(props);

            const timestamp = new Date().getTime();
            const randomNum = Math.floor(Math.random() * 1000);
            const modifiedTimestamp = timestamp.toString().slice(5);
            const randomid = `${modifiedTimestamp}${randomNum}`;
            handleChange(control.value.path, randomid);

            return {
                control,
                handleChange,
                randomid,
            };
        },

        render() {
            const control = this.control;
            return h("div", {}, [
                h(
                    "div",
                    { className: "renderer-label-container" },
                    h("p", { className: "renderer-label" }, control?.schema?.label || "", [
                        h("span", { className: "renderer-required" }, control.required ? "*" : ""),
                    ]),
                    control?.schema?.additionalInfo
                        ? h(QIcon, { name: "info", color: "primary" }, [
                              h(ToolTip, h("b", control?.schema?.additionalInfo)),
                          ])
                        : null,
                ),
                h(QInput, {
                    modelValue: this.randomid,
                    type: control.schema?.format || "text",
                    outlined: true,
                    dense: true,
                    disable: true,
                    error: !!control.errors,
                    errorMessage: control.errors,
                    "onUpdate:modelValue": (event) => {
                        this.handleChange(control.path, event);
                    },
                }),
            ]);
        },
    }),
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type == "randomid"),
    ),
};
