import { ref, watch, computed } from "vue";
import { useDashboardStore } from "@/store/configStore/dashboardStore";
import { useRoute } from "vue-router";

export function useDashboard() {
    const dashboardStore = useDashboardStore();
    const bitems = ref([]);
    const route = useRoute();

    const updateDashboardOnChange = () => {
        if (dashboardStore.singleDashboard) return;
        if (route.params.folderId) {
            const folder = dashboardStore.findFolderById(dashboardStore.dashboards, route.params.folderId);
            bitems.value = [{ text: "Home", href: "/" }, ...extractNames(folder)];
        } else {
            const result = dashboardStore.findDashboardByUrl(route.fullPath);
            bitems.value = [{ text: "Home", href: "/" }, ...extractNames(result.currentDashboard, true)];
        }

        if (bitems.value.length > 1) {
            dashboardStore.hideMainBreadCrumb(true);
        } else {
            dashboardStore.hideMainBreadCrumb(false);
        }
    };

    const extractNames = (obj, isDash = false) => {
        let names = [];
        let current = obj;

        while (current) {
            names.unshift({
                text: current.label ? current.label : current.name,
                href: `/folder/${current.id}`,
            });
            if (isDash) {
                current = current.folder ? current.folder : current.parent;
            } else {
                current = current.parent;
            }
        }
        if (names.length > 0) {
            delete names[names.length - 1]["href"];
            names[names.length - 1]["active"] = true;
        }

        return names;
    };

    watch(
        () => dashboardStore.dashboards,
        () => {
            updateDashboardOnChange();
        },
        { deep: true, immediate: true },
    );

    watch(
        () => route,
        () => {
            updateDashboardOnChange();
        },
        { deep: true, immediate: true },
    );

    const userDashboards = computed(() => dashboardStore.dashboards);
    return {
        bitems,
        userDashboards,
    };
}
