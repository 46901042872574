import { defineComponent, h } from "vue";
import { and, isControl, rankWith, scopeEndsWith } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import gSelect from "@/shared/components/gSelect.vue";
import QIcon from 'quasar/src/components/icon/QIcon.js';;
import ToolTip from "@/shared/components/ToolTip.vue";

export const entry = {
    renderer: defineComponent({
        name: "assetApiSelect",
        props: {
            ...rendererProps(),
        },
        setup(props) {
            return useJsonFormsControl(props);
        },
        mounted() {
            // to handle if have default text
            if (this.control.schema.defaultValue) {
                this.handleChange(this.control.path, this.control.schema.defaultValue);
            }
        },
        render() {
            const control = this.control;
            const schemaOptions = control.schema.options;
            const multiple = schemaOptions.multiple ?? false;
            const filters = schemaOptions.filters ?? {};
            const customQuery = Object.keys(filters)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
                .join("&");

            return h("div", {}, [
                h(
                    "div",
                    { className: "renderer-label-container" },
                    h("p", { className: "renderer-label" }, control?.schema?.label || "", [
                        h("span", { className: "renderer-required" }, control.required ? "*" : ""),
                    ]),
                    control?.schema?.additionalInfo
                        ? h(QIcon, { name: "info", color: "primary" }, [
                              h(ToolTip, h("b", control?.schema?.additionalInfo)),
                          ])
                        : null,
                ),

                h(gSelect, {
                    modelValue: control.data,
                    api: schemaOptions.api,
                    dense: true,
                    outlined: true,
                    optionLabel: "name",
                    optionValue: "name",
                    searchKey: "name",
                    multiple,
                    useChips: multiple,
                    error: !!control.errors,
                    errorMessage: control.errors,
                    mapOptions: true,
                    emitValue: true,
                    customQuery: `&${customQuery}`,
                    "onUpdate:modelValue": (event) => {
                        this.handleChange(control.path, event);
                    },
                }),
            ]);
        },
    }),
    tester: rankWith(
        1,
        and(isControl, (schema) => schema.type === "assetApiSelect"),
    ),
};
