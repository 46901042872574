import httpClient from "./http-client";
import common from "./common";
import { getActivePinia } from "pinia";

export default {
    /**@returns {Promise<import("@/types").LocalUser>} */
    async getCurrentUser() {
        let user = undefined;
        try {
            if (localStorage.getItem("user-info")) {
                user = JSON.parse(localStorage.getItem("user-info"));
                await this.validateRoleLogin(user);
                return user;
            } else {
                let response = await this.getcurrentLogin();
                if (response && response.data && response.data.user) {
                    user = response.data.user;
                    await this.validateRoleLogin(user);
                    localStorage.setItem("user-info", JSON.stringify(user));
                    localStorage.setItem("access_token", response.data.access);
                    localStorage.setItem("refresh_token", response.data.refresh);
                }
            }
        } catch (e) {
            console.error(e);
        }
        if (user == undefined && !localStorage.getItem("login-page-shown")) {
            window.location.href = "/auth/login";
            localStorage.setItem("login-page-shown", "true");
        }
        return user;
    },
    async validateRoleLogin(user) {
        const validated = localStorage.getItem("x-valid-role") || false;
        if (!validated) {
            const loginRole = localStorage.getItem("x-log-role") || "auth";
            if (loginRole !== "auth") {
                if (!user.userRole.includes(loginRole)) {
                    localStorage.setItem("x-log-role", "auth");
                    await this.logout();
                }
            }
            localStorage.setItem("x-valid-role", true);
        }
    },
    async getCustomerLogo() {
        let customerLogo = undefined;
        try {
            if (localStorage.getItem("customer-logo")) {
                return JSON.parse(localStorage.getItem("customer-logo"));
            } else {
                let response = await this.getCurrentCustomerLogo();
                if (response && response.data && response.data.logo) {
                    customerLogo = response.data;
                    localStorage.setItem("customer-logo", JSON.stringify(customerLogo));
                }
            }
        } catch (e) {
            console.error(e);
        }
        return customerLogo;
    },
    async getcurrentLogin() {
        try {
            return await httpClient.get("/current-login/");
        } catch (e) {
            console.error(e);
        }
    },
    async getCurrentCustomerLogo() {
        try {
            return await httpClient.get("/iotapp/customer-logo/");
        } catch (e) {
            console.error(e);
        }
    },
    async logout() {
        let res = await httpClient.get(`/logout/`, false);
        if (res.status == 200) {
            common.clearLocalStorage();
            getActivePinia()._s.forEach((store) => store.$reset());
            const urlObj = new URL(res.data.redirectURL);
            const loginUrlBase = localStorage.getItem("x-log-role") || "auth";
            let redirectUrl = `${window.location.origin}/${loginUrlBase}/login/`;
            let postLogoutRedirectUri = urlObj.searchParams.get("post_logout_redirect_uri");
            if (postLogoutRedirectUri) {
                postLogoutRedirectUri = `${window.location.origin}/${loginUrlBase}/login/`;
                urlObj.searchParams.set("post_logout_redirect_uri", postLogoutRedirectUri);
                redirectUrl = urlObj.toString();
            }
            window.location.replace(redirectUrl);
        }
    },
    /**@returns {import("@/types").LocalUser} */
    getLocalUser() {
        return JSON.parse(localStorage.getItem("user-info"));
    },
};

export const USER_ROLES = Object.freeze({
    sysadmin: "sysadmin",
    customeradmin: "customeradmin",
    localadmin: "localadmin",
    // other user roles are customeruser
});

export const excludeUserRoleList = [
    "localadmin",
    "infraadmin",
    "vendor",
    "citylead",
    "areahead",
    "fieldexecutive",
    "storemanager",
    "regionalhead",
];
