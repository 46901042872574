import { and, isControl, rankWith } from "@jsonforms/core";
import multiLevelSelect from "./components/multiLevelSelect.vue";

export const entry = {
    renderer: multiLevelSelect,
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type == "multiLevelSelect"),
    ),
};
