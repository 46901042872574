import { defineComponent, h } from "vue";
import { and, isControl, rankWith } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import QRating from 'quasar/src/components/rating/QRating.js';;
import QIcon from 'quasar/src/components/icon/QIcon.js';;
import ToolTip from "@/shared/components/ToolTip.vue";

export const entry = {
    renderer: defineComponent({
        name: "starRatingRenderer",
        props: {
            ...rendererProps(),
        },
        setup(props) {
            const { control, handleChange } = useJsonFormsControl(props);
            return { control, handleChange };
        },
        mounted() {
            // to handle if have default text
            if (this.control.schema.defaultValue) {
                this.handleChange(this.control.path, this.control.schema.defaultValue);
            }
        },
        render() {
            const { control, handleChange } = this;
            return h("div", { className: "q-py-sm" }, [
                h(
                    "div",
                    { className: "renderer-label-container" },
                    h("p", { className: "renderer-label" }, control?.schema?.label || "", [
                        h("span", { className: "renderer-required" }, control.required ? "*" : ""),
                    ]),
                    control?.schema?.additionalInfo
                        ? h(QIcon, { name: "info", color: "primary" }, [
                              h(ToolTip, h("b", control?.schema?.additionalInfo)),
                          ])
                        : null,
                ),
                h(QRating, {
                    modelValue: control.data,
                    "onUpdate:modelValue": (event) => {
                        handleChange(control.path, event);
                    },
                    max: control.schema?.max || 5,
                    icon: control.schema?.icon || "star",
                    size: control.schema?.size || "md",
                    color: "secondary",
                    colorSelected: ["light-green-3", "light-green-6", "green", "green-9", "green-10"],
                }),
            ]);
        },
    }),
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type === "rating"),
    ),
};
