import { and, isControl, rankWith } from "@jsonforms/core";
import dateRangeRenderer from "./components/dateRangeRenderer.vue";

export const entry = {
    renderer: dateRangeRenderer,
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type == "daterange"),
    ),
};
