import { and, isControl, rankWith } from "@jsonforms/core";
import dateTimeRenderer from "./components/dateTimeRenderer.vue";

export const entry = {
    renderer: dateTimeRenderer,
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type == "dateTime"),
    ),
};
