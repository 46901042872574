import Notify from 'quasar/src/plugins/Notify.js';;

/**@typedef {import("quasar").QNotifyCreateOptions} NotifyConfig */

export default {
    /**@param {NotifyConfig} [opts={}] */
    success(message, opts = {}) {
        Notify.create({
            message: message,
            position: "top-right",
            timeout: 3000,
            progress: false,
            color: "green",
            ...opts,
        });
    },
    /**@param {NotifyConfig} [opts={}] */
    error(message, opts = {}) {
        Notify.create({
            message: message,
            position: "top-right",
            timeout: 5000,
            progress: false,
            color: "red",
            ...opts,
        });
    },

    hide() {
        // Close Toast here!
    },
    /**@param {NotifyConfig} [opts={}] */
    networkError(message, opts = {}) {
        Notify.create({
            message,
            position: "top-right",
            ...opts,
        });
    },
};
