import userService from '@/shared/services/user-service';
import { defineStore } from 'pinia';

// Define constants for localStorage keys
const USER_INFO_KEY = 'user-info';

export const useUserPermissionStore = defineStore('userPermissionStore', {
  state: () => ({
    permissions: {},
    isSet: false,
  }),
  actions: {
    isPermissionSet() {
      return this.isSet;
    },
    async setPermissions() {
      if (this.isSet) {
        return;
      }

      const userInfoString = localStorage.getItem(USER_INFO_KEY);
      if (userInfoString) {
        const userInfo = JSON.parse(userInfoString);
        const permissions = userInfo.groups[0]?.permissions || [];
        permissions.forEach((permission) => {
          this.permissions[permission.codename] = permission.name;
        });
        this.isSet = true;
      } else {
        const user = await userService.getCurrentUser();
        if (user) {
          this.setPermissions();
        }
      }
    },
    checkPermission(codename) {
      return codename in this.permissions;
    },
  },
});
