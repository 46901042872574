<template>
    <div>
        <div class="renderer-label-container">
            <p class="renderer-label">
                {{ control?.schema?.label }}
                <span class="renderer-required" v-if="control?.required">*</span>
            </p>
            <div v-if="control?.schema?.additionalInfo" class="icon-container">
                <q-icon name="info" class="text-primary icon-right">
                    <ToolTip>
                        <b>{{ control?.schema?.additionalInfo }}</b>
                    </ToolTip>
                </q-icon>
            </div>
        </div>
        <q-input
            outlined
            v-model="control.data"
            dense
            :error="!!control.errors"
            :error-message="control.errors">
            <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-date
                            minimal
                            v-model="control.data"
                            :mask="control.schema?.withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'"
                            @update:model-value="updated">
                        </q-date>
                    </q-popup-proxy>
                </q-icon>
            </template>

            <template v-slot:append v-if="control.schema?.withTime">
                <q-icon name="access_time" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-time
                            v-model="control.data"
                            mask="YYYY-MM-DD HH:mm"
                            format24h
                            @update:model-value="updated">
                        </q-time>
                    </q-popup-proxy>
                </q-icon>
            </template>
        </q-input>
    </div>
</template>

<script>
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
export default {
    props: {
        ...rendererProps(),
    },
    setup(props) {
        return useJsonFormsControl(props);
    },
    mounted() {
        // to handle if have default text
        if (this.control.schema.defaultValue) {
            this.handleChange(this.control.path, this.control.schema.defaultValue);
        }
    },
    methods: {
        updated(e) {
            this.handleChange(this.control.path, e);
        },
    },
};
</script>
