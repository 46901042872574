import { defineComponent, h } from "vue";
import { and, isControl, rankWith, schemaMatches } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import QSelect from 'quasar/src/components/select/QSelect.js';;
import QIcon from 'quasar/src/components/icon/QIcon.js';;
import ToolTip from "@/shared/components/ToolTip.vue";

export const entry = {
    renderer: defineComponent({
        name: "selectRender",
        props: {
            ...rendererProps(),
        },
        setup(props) {
            const { control, handleChange } = useJsonFormsControl(props);
            return { control, handleChange };
        },
        mounted() {
            // to handle if have default text
            if (this.control.schema.defaultValue) {
                this.handleChange(this.control.path, this.control.schema.defaultValue);
            }
        },
        render() {
            const { control, handleChange } = this;
            const options = control.schema?.enum || [];

            return h("div", {}, [
                h(
                    "div",
                    { className: "renderer-label-container" },
                    h("p", { className: "renderer-label" }, control?.schema?.label || "", [
                        h("span", { className: "renderer-required" }, control.required ? "*" : ""),
                    ]),
                    control?.schema?.additionalInfo
                        ? h(QIcon, { name: "info", color: "primary" }, [
                              h(ToolTip, h("b", control?.schema?.additionalInfo)),
                          ])
                        : null,
                ),
                h(QSelect, {
                    modelValue: control.data,
                    outlined: true,
                    dense: true,
                    error: !!control.errors,
                    errorMessage: control.errors,
                    mapOptions: true,
                    emitValue: true,
                    options: options.map((option) => ({ label: option, value: option })),
                    "onUpdate:modelValue": (event) => {
                        handleChange(control.path, event);
                    },
                }),
            ]);
        },
    }),
    tester: rankWith(
        3,
        and(
            isControl,
            schemaMatches((schema) => Object.prototype.hasOwnProperty.call(schema, "enum")),
        ),
    ),
};
