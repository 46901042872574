<template>
    <div>
        <q-btn color="primary" flat class="notification-btn">
            <ToolTip><b>Notifications</b></ToolTip>
            <q-icon name="notifications" class="notification-icon"></q-icon>
            <q-badge floating color="red" v-if="unreadCount > 0">{{ unreadCountMask }}</q-badge>
            <q-menu class="notification-menu">
                <q-list id="scroll-target" class="notification-list" style="min-height: 500px">
                    <div class="heading">
                        <div class="header">Notifications</div>
                        <div class="options" v-if="unreadCount > 0">
                            <span @click="markAllAsRead">Mark all as read</span>
                        </div>
                    </div>
                    <q-infinite-scroll
                        v-if="userNotifications.length > 0"
                        :disable="pagination.loading"
                        @load="onLoadMenu"
                        :offset="50"
                        :debounce="10"
                        scroll-target="#scroll-target">
                        <q-item
                            v-for="{ notification, id, isRead, createdAt } in userNotifications"
                            :key="id">
                            <div class="notification-content">
                                <div class="avatar" v-if="notification?.actor?.email == 'system generated'">
                                    <q-img
                                        class="system-gen-img"
                                        src="@/assets/images/system.png"
                                        spinner-color="primary"
                                        alt="avatar" />
                                    <ToolTip>
                                        <b>System Generated</b>
                                    </ToolTip>
                                </div>
                                <div class="avatar" v-else>
                                    <q-img
                                        :src="notification?.actor?.profile"
                                        spinner-color="primary"
                                        class="img"
                                        alt="avatar"
                                        v-if="notification?.actor?.profile" />
                                    <p class="initial" v-else>
                                        {{ notification?.actor?.email?.slice(0, 1) || "U" }}
                                    </p>
                                    <ToolTip>
                                        <b>{{ notification?.actor?.email }}</b>
                                    </ToolTip>
                                </div>
                                <div class="content">
                                    <div class="title">{{ notification.message }}</div>

                                    <div class="link">
                                        <q-icon
                                            name="link"
                                            class="link-icon"
                                            size="12px"
                                            color="primary"></q-icon>
                                        <a :href="'/' + notification.metadata.url">{{
                                            notification.metadata.urlMask
                                        }}</a>
                                    </div>
                                    <div class="time">{{ duration(createdAt) }}</div>
                                </div>
                                <div
                                    class="unread-label"
                                    :class="isRead ? 'hide-view' : ''"
                                    @click="updateIsRead(!isRead, id)">
                                    <div class="dot" v-if="isRead == false"></div>
                                    <ToolTip
                                        ><b
                                            >{{ isRead == false ? "Mark as read" : "Mark as unread" }}
                                        </b></ToolTip
                                    >
                                </div>
                            </div>
                        </q-item>

                        <template v-slot:loading>
                            <div class="text-center q-my-md">
                                <q-spinner-dots color="primary" size="20px" />
                            </div>
                        </template>
                    </q-infinite-scroll>
                    <div v-else class="no-notification">
                        <img src="@/assets/images/no_notif.svg" alt="no notification" />
                    </div>
                </q-list>
            </q-menu>
        </q-btn>
    </div>
</template>

<script>
import httpClient from "@/shared/services/http-client";
import moment from "moment";

export default {
    name: "notificationInbox",
    data() {
        return {
            userNotifications: [],
            unreadCount: 0,
            pagination: {
                page: 1,
                limit: 10,
                lastPage: 0,
                totalCount: 0,
                loading: false,
            },
            iScrollTimeout: null,
            refreshId: null,
        };
    },
    async mounted() {
        await this.fetchUserNotifications();
        await this.runInterval();
    },
    beforeUnmount() {
        clearTimeout(this.refreshId);
        clearTimeout(this.iScrollTimeout);
    },
    computed: {
        unreadCountMask() {
            return this.unreadCount > 99 ? "99+" : this.unreadCount;
        },
    },
    methods: {
        duration(date) {
            return moment(date).fromNow();
        },
        async runInterval() {
            clearTimeout(this.refreshId);
            this.refreshId = setTimeout(async () => {
                if (!this.pagination.loading) await this.fetchUserNotifications(1);
                await this.runInterval();
            }, 30000);
        },
        async fetchUserNotifications(p = null) {
            this.pagination.loading = true;
            try {
                const { page, limit } = this.pagination;
                let query = `?page=${p ? p : page}&limit=${limit}&orderBy=-createdAt`;
                const response = await httpClient.get(`/notification/inbox/${query}`);
                if (response.status === 200 && response.data) {
                    const data = response.data.contents;
                    this.unreadCount = response.data.unreadCount;

                    // Remove duplicates
                    const notificationMap = new Map(this.userNotifications.map((notif) => [notif.id, notif]));
                    data.forEach((notif) => notificationMap.set(notif.id, notif));

                    this.userNotifications = Array.from(notificationMap.values()).sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                    );
                    this.pagination.totalCount = response.data.totalCount;
                    this.pagination.lastPage = Math.ceil(this.pagination.totalCount / limit);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.pagination.loading = false;
            }
        },
        async updateIsRead(read = true, id) {
            if (read) {
                this.unreadCount--;
            } else {
                this.unreadCount++;
            }
            const uuid = this.userNotifications.filter((i) => i.id === id)[0].uuid;
            this.userNotifications.filter((i) => i.id === id)[0].isRead = read;
            const formData = {
                isRead: read,
                uuid,
            };
            try {
                await httpClient.put(`/notification/inbox/${id}/`, formData);
            } catch (error) {
                console.error(error);
            }
        },
        onLoadMenu(i, done) {
            if (this.pagination.loading) {
                return;
            }
            if (this.pagination.page < this.pagination.lastPage) {
                clearTimeout(this.iScrollTimeout);
                this.iScrollTimeout = setTimeout(async () => {
                    this.pagination.page++;
                    await this.fetchUserNotifications();
                }, 500);
            } else {
                done(true);
                return;
            }
        },
        async markAllAsRead() {
            try {
                await httpClient.post("/notification/inbox/mark-all-as-read/", {}, false);
                this.unreadCount = 0;
                this.userNotifications.map((u) => (u.isRead = true));
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.notification-icon {
    transform: rotate(35deg);
}
.notification-menu {
    min-height: 400px;
}
.notification-list {
    min-width: 500px;
    max-width: 600px;
    position: relative;
    .heading {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: white;
        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #ededed;
        .header {
            font-size: 18px;
            font-weight: 600;
            color: $deep-dark;
        }
        .options {
            span {
                color: $primary;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }
    .notification-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 10px;
        .avatar {
            width: 55px;
            height: 50px;
            border-radius: 50%;
            display: grid;
            place-items: center;
            overflow: hidden;
            border: 2px solid rgb(237, 237, 237);
            position: relative;
            .initial {
                text-transform: uppercase;
            }

            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .system-gen-img {
                width: 50%;
                height: 50%;
                object-fit: contain;
            }
        }

        .content {
            width: 100%;
            padding-left: 15px;

            .title {
                font-size: 14px;
                font-weight: 500;
                color: $deep-dark;
            }

            .time {
                font-size: 12px;
                color: $text-secondary-color;
            }

            .link {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;
                .link-icon {
                    transform: rotate(-45deg);
                }

                a {
                    text-decoration: none;
                    color: $primary;
                    font-size: 12px;
                }
                &:hover {
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }

        .unread-label {
            width: 19px;
            height: 17px;
            border-radius: 50%;
            cursor: pointer;
            display: grid;
            place-items: center;

            &.hide-view {
                opacity: 0;
            }

            .dot {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $primary;
            }

            &:hover {
                background-color: rgb(225, 225, 225);
            }
        }

        &:hover {
            background-color: rgb(248, 248, 248);
            .unread-label {
                border: 1px solid rgb(198, 198, 198);
            }
            .hide-view {
                opacity: 1;
            }
        }
    }
}
.no-notification {
    width: 100%;
    height: 400px;
    display: grid;
    place-items: center;
    img {
        width: 55%;
        height: 55%;
        object-fit: cover;
    }
}
</style>
