<template>
    <router-view />
    <CookieConsent />
</template>

<script setup>
import { onMounted } from "vue";
import { useDashboardStore } from "./store/configStore/dashboardStore";
import CookieConsent from "./components/cookieConsent/cookieConsent.vue";

const dashboardStore = useDashboardStore();

// Stop error resizeObserver
const debounce = (callback, delay) => {
    let tid;
    return function (...args) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
            callback.apply(ctx, args);
        }, delay);
    };
};

// create resize observer
const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
    constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
    }
};

onMounted(() => {
    const miniFyBreadCrumb = localStorage.getItem("miniFyBreadCrumb") === "true";
    const breadCrumbTitle = localStorage.getItem("breadCrumbTitle");
    dashboardStore.$patch({ miniFyBreadCrumb, breadCrumbTitle });
});
</script>
