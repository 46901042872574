const routesMap = [
    {
        path: "/",
        name: "LandingPage",
    },
    {
        path: "/assets",
        name: "AssetsPage",
    },
    {
        path: "/assets/view/:id",
        name: "AssetDetailsPage",
    },
    {
        path: "/assets/create",
        name: "AssetCreatePage",
    },
    {
        path: "/assets/edit/:id",
        name: "AssetEditPage",
    },
    {
        path: "/assign-asset",
        name: "AssignAssetPage",
    },
    {
        path: "/customers",
        name: "CustomerListPage",
    },
    {
        path: "/customers/create",
        name: "CustomerCreatePage",
    },
    {
        path: "/customers/:id/view/",
        name: "CustomerViewPage",
    },
    {
        path: "/customers/:id/edit/",
        name: "CustomerEditPage",
    },
    {
        path: "/customers/:id/iot-fw-cred/",
        name: "CustomerIotCRUDPage",
    },
    {
        path: "/devices",
        name: "DeviceListPage",
    },
    {
        path: "/devices/:id/view",
        name: "DeviceDetailsPage",
    },
    {
        path: "/devices/:id/edit",
        name: "DeviceEditPage",
    },
    {
        path: "/devices/discover",
        name: "DeviceDiscoverPage",
    },
    {
        path: "/devices/discover/:id/edit",
        name: "DeviceDiscoverEditPage",
    },
    {
        path: "/device/create",
        name: "DeviceCreatePage",
    },
    {
        path: "/devices/assign",
        name: "DeviceAssignPage",
    },
    {
        path: "/device-health",
        name: "DeviceHealthPage",
    },
    {
        path: "/device-template",
        name: "DeviceTemplatePage",
    },
    {
        path: "/view-device-template/view/:templateId",
        name: "DeviceTemplateViewPage",
    },
    {
        path: "/view-device-template/edit/:templateId",
        name: "DeviceTemplateEditPage",
    },
    {
        path: "/device-template/create/",
        name: "DeviceTemplateCreatePage",
    },
    {
        path: "/device-inventory",
        name: "DeviceInventoryPage",
    },
    {
        path: "/tickets",
        name: "TicketManagementPage",
    },
    {
        path: "/tickets/:type",
        name: "TicketsAllPage",
    },
    {
        path: "/tickets/:type/create",
        name: "TicketCreatePage",
    },
    {
        path: "/tickets/:type/:id",
        name: "TicketEditPage",
    },
    {
        path: "/tickets/:type/view",
        name: "TicketViewPage",
    },
    {
        path: "/escalation-tickets",
        name: "EscalationTicketsPage",
    },
    {
        path: "/escalation-tickets/create",
        name: "EscalationTicketCreatePage",
    },
    {
        path: "/escalation-tickets/:id/edit",
        name: "EscalationTicketEditPage",
    },
    {
        path: "/escalation-tickets/view/",
        name: "EscalationTicketViewPage",
    },
    {
        path: "/work-orders/",
        name: "WorkOrdersPage",
    },
    {
        path: "/work-orders/:id/view",
        name: "WorkOrderViewPage",
    },
    {
        path: "/permissions/",
        name: "PermissionsPage",
    },
    {
        path: "/assign-ticket",
        name: "AssignTicketPage",
    },
    {
        path: "/import",
        name: "ImportPage",
    },
    {
        path: "/dashboards",
        name: "DashboardListPage",
    },
    {
        path: "/dashboards/create",
        name: "DashboardCreatePage",
    },
    {
        path: "/dashboards/:id/edit",
        name: "DashboardEditPage",
    },
    {
        path: "/dashboards/component/create",
        name: "DashboardComponentCreatePage",
    },
    {
        path: "/dashboards/component/:id/edit",
        name: "DashboardComponentEditPage",
    },
    {
        path: "/dashboards/component/:id/view",
        name: "DashboardComponentViewPage",
    },
    {
        path: "/dashboards/:id/view",
        name: "DashboardViewPage",
    },
    {
        path: "/myprofile",
        name: "UserProfilePage",
    },
    {
        path: "/administration",
        name: "CustomerAdministrationPage",
    },
    {
        path: "/users",
        name: "RequestRegistrationPage",
    },
    {
        path: "/registration-requests/:id/view",
        name: "RegistrationRequestViewPage",
    },
    {
        path: "/user/:id",
        name: "UserDetailsPage",
    },
    {
        path: "/user-details/:id",
        name: "UserDetailsViewPage",
    },
    {
        path: "/email-done",
        name: "EmailConfirmationPage",
    },
].map((c, i) => ({ ...c, id: i + 1 }));

export default routesMap;
