<template>
    <section>
        <NavigationBar v-if="!$route.meta.hideSideNav" />
        <router-view />
        <FooterApp v-if="!hideNav && !$route.meta.hideNavigation" />
    </section>
</template>
<script>
import NavigationBar from "../components/containers/Navigation.vue";
import FooterApp from "../components/containers/Footer.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import "@/styles/quasar.scss";

export default {
    components: {
        NavigationBar,
        FooterApp,
    },
    setup() {
        const route = useRoute();
        const hideNav = computed(() => route.path.includes("/login"));
        return {
            hideNav,
        };
    },
    watch: {
        $route: {
            handler(route) {
                if (
                    window.location !== window.parent.location ||
                    route.name == "extension-app" ||
                    route.name == "django-extension-app"
                ) {
                    route.meta.hideNavigation = true;
                } else {
                    route.meta.hideNavigation = false;
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
