import { defineComponent, h } from "vue";
import { and, isControl, rankWith } from "@jsonforms/core";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';;
import QIcon from 'quasar/src/components/icon/QIcon.js';;
import ToolTip from "@/shared/components/ToolTip.vue";

export const entry = {
    renderer: defineComponent({
        name: "radioButtonRenderer",
        props: {
            ...rendererProps(),
        },
        setup(props) {
            const { control, handleChange } = useJsonFormsControl(props);
            return { control, handleChange };
        },
        mounted() {
            // to handle if have default text
            if (this.control.schema.defaultValue) {
                this.handleChange(this.control.path, this.control.schema.defaultValue);
            }
        },
        render() {
            const { control, handleChange } = this;
            const options = control.schema?.options || [];
            return h("div", {}, [
                h(
                    "div",
                    { className: "renderer-label-container" },
                    h("p", { className: "renderer-label" }, control?.schema?.label || "", [
                        h("span", { className: "renderer-required" }, control.required ? "*" : ""),
                    ]),
                    control?.schema?.additionalInfo
                        ? h(QIcon, { name: "info", color: "primary" }, [
                              h(ToolTip, h("b", control?.schema?.additionalInfo)),
                          ])
                        : null,
                ),
                h(QOptionGroup, {
                    modelValue: control.data,
                    options: options,
                    inline: control.schema?.inline || false,
                    "onUpdate:modelValue": (event) => {
                        handleChange(control.path, event);
                    },
                }),
            ]);
        },
    }),
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type === "radio"),
    ),
};
