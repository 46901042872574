import { and, isControl, rankWith } from "@jsonforms/core";
import timeRenderer from "./components/timeRenderer.vue";

export const entry = {
    renderer: timeRenderer,
    tester: rankWith(
        2,
        and(isControl, (schema) => schema.type == "time"),
    ),
};
