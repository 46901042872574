<template>
    <div class="renderer-label-container">
        <p class="renderer-label">
            {{ control?.schema?.label }}
            <span class="renderer-required" v-if="control?.required">*</span>
        </p>
        <div v-if="control?.schema?.additionalInfo" class="icon-container">
            <q-icon name="info" class="text-primary icon-right">
                <ToolTip>
                    <b>{{ control?.schema?.additionalInfo }}</b>
                </ToolTip>
            </q-icon>
        </div>
    </div>
    <q-file
        outlined
        v-model="selectedFile"
        multiple
        use-chips
        counter
        dense
        :error="!!control.errors"
        :error-message="control.errors"
        :counter-label="counterLabelFn"
        @update:model-value="updated">
        <template v-slot:prepend>
            <q-icon name="attach_file" />
        </template>
    </q-file>

    <div class="file-previews" v-if="this.preview.length > 0">
        <div v-for="(preview, index) in this.preview" :key="index" class="file-preview">
            <img
                v-if="preview.type.startsWith('image/')"
                :src="preview.url"
                :alt="preview.name"
                class="attachments" />
            <div v-else>
                <p class="attachments"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
export default {
    props: {
        ...rendererProps(),
    },
    setup(props) {
        return useJsonFormsControl(props);
    },

    data() {
        return {
            selectedFile: [],
            preview: [],
        };
    },
    watch: {
        selectedFile(newFiles) {
            this.generatePreviews(newFiles);
        },
    },
    methods: {
        updated(e) {
            this.handleChange(this.control.path, e);
        },
        counterLabelFn({ totalSize, filesNumber }) {
            return `${filesNumber} files | ${totalSize}`;
        },
        generatePreviews(files) {
            this.preview = [];
            for (const file of files) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.preview.push({
                        name: file.name,
                        type: file.type,
                        url: e.target.result,
                    });
                };
                reader.readAsDataURL(file);
            }
        },
    },
};
</script>

<style scoped>
.file-previews {
    margin-top: 10px;
}
.file-preview {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
}
.file-preview img {
    max-width: 200px;
    max-height: 200px;
}
/* .file-preview p {
    margin: 5px 0 0 0;
} */

.attachments {
    width: 130px;
    height: 130px;
    background-image: url(@/assets/images/attachment-placeholder.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    background-color: #e1f0f6;
    border-radius: 5px;
    object-fit: cover;
}
</style>
