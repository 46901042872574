<template>
    <q-breadcrumbs
        class="text-grey"
        active-color="#111"
        v-if="!$route.meta.hideNavigation && !hideBreadCrumb">
        <template v-slot:separator>
            <q-icon size="1.2em" name="navigate_next" color="secondary" />
        </template>

        <q-breadcrumbs-el
            v-for="(item, i) in bitems"
            :key="i"
            :label="item.text"
            class="breadcrumb"
            :class="{ active: item?.active }"
            :to="item?.href || ''"
            @click="route(item)">
        </q-breadcrumbs-el>
    </q-breadcrumbs>
</template>

<script>
import { useDashboardStore } from "@/store/configStore/dashboardStore";

export default {
    name: "BreadCrumb",
    emits: ["itemClicked"],
    props: {
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
        preventSlice: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const dashboardStore = useDashboardStore();
        return {
            dashboardStore,
        };
    },
    methods: {
        route(item) {
            if (item.click) this.$emit("itemClicked", true);
        },
    },
    computed: {
        hideBreadCrumb() {
            return this.dashboardStore.hideBreadCrumb;
        },
        bitems() {
            const miniFyBreadCrumb = this.dashboardStore.miniFyBreadCrumb;
            const bitems = JSON.parse(JSON.stringify(this.items));

            if (miniFyBreadCrumb) {
                // bitems.splice(0, 1);
                bitems[0] = {
                    text: "Home",
                    href: "/",
                    active: false,
                };
            }
            return bitems;
        },
    },
};
</script>

<style scoped>
.breadcrumb {
    cursor: pointer;
}

.active {
    /* color: red; */
    font-weight: 600;
}
</style>
