export const CONSTANTS = Object.freeze({
    // Auth Messages
    ERR_INVALID_CREDENTIALS: "Invalid credentials",
    ERR_INVALID_USERNAME: "Invalid UserName",
    ERR_ACCOUNT_LOCKED: "Account Locked! Please try again later",
    ERR_GENERATE_CAPTCHA: "Unable to generate Captcha",
    ERR_CAPTACHA_VERIFICATION: "Captcha Verification Failed!",
    ERR_INVALID_CAPTACHA: "Invalid Captcha",
    ERR_LOGIN_FAILED: "Login Failed",
    ERR_INVALID_LOGIN: "This mobile number is not registered!",
    ERR_VERIFICATION_OTP: "The OTP you entered is incorrect. Please try again.",
    ERR_UNAUTHORIZED: "Unauthorized",
    ERR_NOT_FOUND: "Not Found",
    ERR_SERVICE_UNAVAILABLE: "Service Unavailable",

    // User Messages
    LOGIN_SUCCESS: "Login Successful",
    USER_ALREADY_EXISTS: "User already exists",
    LOADING_MSG: "Loading... Please wait",
    MSG_USER_UPDATE_SUCCESS: "User Updated Successfully",
    MSG_USER_CREATE_SUCCESS: "User Created Successfully",

    // Asset Messages
    MSG_ASSET_DELETED: "Asset deleted successfully",
    MSG_ASSET_UPDATED: "Asset updated successfully",
    MSG_ASSET_CREATED: "New asset created successfully",
    MSG_ASSET_CREATING: "Asset creation in progress, Please wait...",
    MSG_ASSET_UPDATING: "Asset Updating in progress, Please wait...",
    MSG_ASSETS_EXISTS: "Assets Already Exists",

    // Ticket Messages
    MSG_TICKET_CREATING: "Ticket Creation in Progress, Please wait..",
    MSG_TICKET_UPDATING: "Ticket Updation in Progress, Please wait..",

    // Event/SubEvent Messages
    /**@param {string} name */
    MSG_EVENT_ALREADY_EXIST: (name) => `'${name}' already exists.`,
    MSG_EVENT_CREATED: "Successfully Created",

    // Device Messages
    MSG_DEVICE_DELETED: "Device deleted successfully",
    MSG_DEVICE_UPDATED: "Update Device call - Success",
    MSG_NO_DEVICE_SELECTED: "No Device Selected",
    MSG_DEVICE_UPDATE_FAIL: "Failed to update device! Try again..",
    MSG_DEVICE_INVENTORY_ADD: "Added device to inventory - Success",
    MSG_DEVICE_INVENTORY_FAIL: "Failed to add device to inventory, Try Again",
    MSG_DEVICE_DISCOVER_AND_ASSIGNING: "Device discover and assigning in progress, Please wait..",
    MSG_FETCHING_DEVICES: "Fetching Devices, Please Wait",

    //Storage Messages
    MSG_STORAGE_DELETED: "Storage deleted successfully",
    MSG_STORAGE_UPDATED: "Storage updated successfully",
    MSG_STORAGE_CREATED: "New Storage created successfully",
    MSG_IMAGE_LOAD_FAIL: "Failed to load preview!",

    // Common Messages
    MSG_NO_DATA: "No Data Found",
    MSG_LOAD_MORE: "Load More ...",
    MSG_DBL_CLICK_TO_SELECT_LOCATION: "Double click on map to select a place.",
    ERR_FAIL_TO_LOADMAP: "Failed to load map! Please check your connectivity.",

    // Live Messages
    LOADING_LIVE_VIEW: "Loading Live, Please Wait..",
    LOADING_IMAGE: "Loading Image..",
    LOADING_LIVE_POPUPS: "Loading Live Popups, Please wait..",
    LOADING_VIDEO: "Loading video, Please wait..",

    //Permission Messages
    MSG_ASSIGN_PERMISSION: "Assigning Permissions, Please Wait..",
    MSG_UNASSIGN_PERMISSION: "Removing Permissions, Please Wait..",
    MSG_NOTIFICATION_ALERT: "Notification Alert Modified",
    MSG_PERMISSION_SUCCESS: "Permission for selected events are updated successfully!",

    //Attachment
    MSG_ATTACHMENT_SUCCESS: "Upload attachments - Success",
    MSG_ATTACHMENT_FAIL: "Upload attachments - Fail",
    MSG_ATTACHMENT_SIZE_FAIL: "Attachments file size too large - Failed to upload",
    MSG_ATTACHMENT_DELETE: "Attachment successfully deleted",
    MSG_ATTACHMENT_UPLOADING: "Attachment Uploading in Progress, Please wait..",
    MSG_ATTACHMENT_DELETE_FAIL: "Delete attachments - Fail",

    // Other Messages
    ERR_UNKNOWN: "Unknown Error",
    MSG_OTP_SUCCESS: "OTP sent successfully to your mobile number ",

    // Validations
    MSG_VALIDATING: "Validating, Please Wait..",

    // customer messages
    MSG_CUSTOMER_CREATING: "Creating Customer, Please Wait..",
    MSG_CUSTOMER_UPDATING: "Updating Customer, Please Wait..",
    CUSTOMER_UPDATE_SUCCESS: "Selected customer updated successfully",
    CUSTOMER_CREATE_SUCCESS: "New customer created successfully",

    //Annotation Player
    MSG_VIDEO_NOT_AVAILABLE: "Video not available :)",
    MSG_PLAYLIST_NOT_AVAILABLE: "Playlist Empty :)",

    //Ticket Messages
    MSG_TICKET_DELETED: "Ticket deleted successfully",
    MSG_TICKET_REPORTED: "Successfully Reported",
    MSG_TICKET_REPORT_CONFLICT: "Already Reported!",

    // event messages
    MSG_EVENT_CHAR_LIMIT: "Please use maximum 250 characters",
    MSG_EVENT_NAME_EMPTY: "Event name can't be empty",

    // Rating message
    MSG_RATING_SUCCESS: "Your rating has been saved",

    // update images
    MSG_IMAGE_UPDATED: "Image updated  successfully",
    MSG_IMAGE_FAILED: "Failed to update Image",

    // ai-config attributes
    MSG_ATTRIBUTE_EXIST: "Attribute already exist!",
    MSG_ATTRIBUTE_VALUE_MISSING: "Attribute value field is required",
    MSG_ATTRIBUTE_REQUIRED: "Attribute field is required",

    MSG_TRIGGER_CONDITION_MISSING: "Add at least one condition",

    // schedule
    DATE_E_CHOOSE_FUTURE_DATE: "Please choose a future start date.",
    DATE_E_CHOOSE_FUTURE_TIME: "Please choose a future start time.",
    DATE_E_SAME_DATE: "Start date time and end date time can't be the same",
    DATE_E_CHOOSE_FUTURE_E_DATE: "Please choose an end date greater than the start date",
    DATE_E_CHOOSE_FUTURE_E_TIME: "Please choose an end time greater than the start date time",
    //Image Analysis
    LOADING_FALSE_TICKET: "Loading false tickets, Please Wait..",
    LOADING_IMAGE_SAMPLES: "Loading image samples, Please Wait..",
    LOADING_DETECTION_IMAGE: "Loading image, Please Wait..",
    LOADING_DETECTIONS: "Loading detections, Please Wait..",
    CROPPING_DETECTIONS: "Cropping detections, Please Wait..",
    DELETE_SAMPLES: "Delete data sample in progress, Please Wait...",

    // Max Characters
    /**@param {number} len */
    MSG_MAX_CHARACTERS: (len) => `Please use a maximum of upto ${len} characters`,

    // Genset
    NA: "NA",
    FETCHING_SITE_DATA: "Fetching Site Data, Please Wait",

    DOMAIN_WHITELIST_ERROR: "⚠ The Email Domain is not Whitelisted ! Please Contact Administrator",
    ERROR_INVALID_CSV: "File contains no valid data for importing",
    ERROR_CREATING_USER: "Error Occurred while Creating User",

    // Client Configuration
    MSG_CLIENTCONF_UPDATE_SUCCESS: `Settings updated successfully`,
    MSG_CLIENTCONF_UPDATE_FAIL: `Failed to update Settings`,
    INVALID_JSON: "Invalid JSON",

    // Enricher
    MSG_NO_THRESHOLD_DATA: "No Data found in the selected threshold range!",
    MSG_THRESHOLD_UPDATED: "Threshold Value Updated successfully",
    // Device Template

    MSG_TEMPLATE_UPDATING: "Template Updation in Progress, Please wait..",
    MSG_TEMPLATE_CREATING: "Template Creation in Progress, Please wait..",
    MSG_TEMPLATE_UPDATION: "Template Updated Successfully",
    MSG_TEMPLATE_FAIL: "Failed To Update Settings",

    //Live View
    MSG_CAMERA_EXISTS: "Camera Already Exists In Grid !",
    // Export Messages
    WORKORDER_EXPORT_FINISH: "workOrder Export Finished",
    STORE_MANAGER_EXPORT_FINISH: "StoreManager Export Finished",
    ATTENDANCE_MANAGEMENT_EXPORT_FINISH: " Attendance Management Export Finished",
    ATTENDANCE_MANAGEMENT_EXPORT_EMPTY: " Export Data Empty For Attendance Management",
    TASK_EXPORT_FINISH: " Task Export Finished",
    USER_EXPORT_FINISH: "User Export Finished",
    ATTACHMENT_SIZE_FAIL: "Please Select Attatchment Size less Than 1 MB",
    DYNAMIC_FORM_EXPORT_FINISH: "Form Responses Export Finished ",
    DYNAMIC_FORM_EXPORT_EMPTY: " Export Data Empty For Form Responses",
    ISSUE_TYPE_EXPORT_FINISH: "Issue Types Export Finished",
    ISSUE_TYPE_EXPORT_EMPTY: "Export Data Empty For Issue Types",
    DEVICE_TEMPLATE_EXPORT_FINISH: "Device Template Export Finished",
    DEVICE_TEMPLATE_EXPORT_EMPTY: "Export Data Empty For Device Template",
    DEVICE_ROUTER_EXPORT_FINISH: "Device Router Export Finished",
    DEVICE_ROUTER_EXPORT_EMPTY: "Export Data Empty For Device Router",
    ACCESS_CONTROL_EXPORT_FINISH: "Access Control Export Finished",
    ACCESS_CONTROL_EXPORT_EMPTY: "Export Data Empty For Access Control",

    // Dynamic Form

    MSG_FORM_CREATING: "Form Submission in Progress, Please wait..",

    // Cost Incurred

    COST_INCORD_UPDATE_SUCCESS: "Cost Incurred Updated Successfully",
    COST_INCORD_UPDATE_FAIL: "Failted To Update Cost Incurred",
});
